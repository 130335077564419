// src/components/SidebarMenu.tsx
import React from "react";
import { Image, Layout, Menu } from "antd";
import { MdAssessment, MdDashboard, MdSettings } from 'react-icons/md';
import { FaMoneyBillWave, FaBoxes, FaShoppingCart, FaShoppingBag, FaFileInvoiceDollar, FaUsers, FaTractor, FaTruckLoading, FaTruck, FaUserFriends } from 'react-icons/fa';
import { Link, useLocation } from "react-router-dom"; // Importando useLocation
import logo from "../../images/logo.png";
import './style.scss';

const { Sider } = Layout;

const SidebarMenu: React.FC = () => {
  const location = useLocation(); // Hook para obter a URL atual

  // Mapear as rotas para corresponder às chaves do Menu
  const selectedKey = () => {
    if (location.pathname.includes('/dashboard')) return '1';
    if (location.pathname.includes('/financeiro')) return '2';
    if (location.pathname.includes('/stock')) return '3';
    if (location.pathname.includes('/vendas')) return '4';
    if (location.pathname.includes('/compras')) return '5';
    if (location.pathname.includes('/nfe')) return '6';
    if (location.pathname.includes('/relatorios')) return '7';
    if (location.pathname.includes('/cobrancas')) return '8';
    if (location.pathname.includes('/crm')) return '9';
    if (location.pathname.includes('/clients')) return '10';
    if (location.pathname.includes('/suppliers')) return '11';
    if (location.pathname.includes('/config')) return '12';

    

    return '1'; // Default para o Dashboard se a URL não corresponder a outra rota
  };

  return (
    <Sider collapsible>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px 0 5px 0px', background: '#fff', height: 65 }}>
        <Image src={logo} alt="Logo" style={{ width: 80 }} preview={false} />
      </div>

      <Menu theme="dark" mode="inline" selectedKeys={[selectedKey()]}>
        <Menu.Item key="1" icon={<MdDashboard />}>
          <Link to="/dashboard">Dashboard</Link>
        </Menu.Item>

        <Menu.Item key="2" icon={<FaMoneyBillWave />}>
          <Link to="/financeiro">Financeiro</Link>
        </Menu.Item>

        <Menu.Item key="3" icon={<FaBoxes />}>
          <Link to="/stock">Estoque</Link>
        </Menu.Item>

        <Menu.Item key="4" icon={<FaShoppingCart />}>
          <Link to="/vendas">Vendas</Link>
        </Menu.Item>

        <Menu.Item key="5" icon={<FaShoppingBag />}>
          <Link to="/compras">Compras</Link>
        </Menu.Item>

        <Menu.Item key="6" icon={<FaFileInvoiceDollar />}>
          <Link to="/nfe">NF-e</Link>
        </Menu.Item>

        <Menu.Item key="7" icon={<MdAssessment />}>
          <Link to="/relatorios">Relatórios</Link>
        </Menu.Item>

        <Menu.Item key="8" icon={<FaMoneyBillWave />}>
          <Link to="/cobrancas">Cobranças</Link>
        </Menu.Item>

        <Menu.Item key="9" icon={<FaUsers />}>
          <Link to="/crm">CRM</Link>
        </Menu.Item>

        <Menu.Item key="10" icon={<FaUserFriends />}>
          <Link to="/clients">Clientes</Link>
        </Menu.Item>

        <Menu.Item key="11" icon={<FaTruck/>}>
          <Link to="/suppliers">Fornecedores</Link>
        </Menu.Item>

        <Menu.Item key="12" icon={<MdSettings />}>
          <Link to="/config">Configurações</Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default SidebarMenu;
