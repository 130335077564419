import axios from 'axios';


export const fetchData = async (endpoint: string): Promise<any> => {
  

    const token = localStorage.getItem('authToken'); 
    const apiUrl = process.env.REACT_APP_API_URL;
    const url = `${apiUrl}/${endpoint}`;

    const config = {
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  };

  try {
    const response = await axios.get(url, config);
    
    return response.data;

  } catch (error: any) {
      if (error.response && error.response.status === 401) {
                    
        localStorage.removeItem('authToken');        
        window.location.href = '/login';  
      } else {
        console.error("Erro ao buscar dados da API:", error);
      }
    
      throw error;      
    }
};

export const apiPost = async (endpoint: string, body: any): Promise<any> => {
  const token = localStorage.getItem('authToken'); 
  const apiUrl = process.env.REACT_APP_API_URL;
  const url = `${apiUrl}/${endpoint}`;

  const config = {
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  };

  try {
    const response = await axios.post(url, body, config);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
                    
      localStorage.removeItem('authToken');        
      window.location.href = '/login';  
    } else {
      console.error("Erro ao enviar dados para API:", error);
    }

    throw error;      
  }
};

export const apiDelete = async (endpoint: string): Promise<any> => {
  const token = localStorage.getItem('authToken'); 
  const apiUrl = process.env.REACT_APP_API_URL;
  const url = `${apiUrl}/${endpoint}`;

  const config = {
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  };

  try {
    const response = await axios.delete(url, config);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
                    
      localStorage.removeItem('authToken');        
      window.location.href = '/login';  
    } else {
      console.error("Erro ao deletar dados na API:", error);
    }

    throw error;      
  }
};


export const apiPut = async (endpoint: string, body: any): Promise<any> => {
  const token = localStorage.getItem('authToken'); 
  const apiUrl = process.env.REACT_APP_API_URL;
  const url = `${apiUrl}/${endpoint}`;

  const config = {
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  };

  try {
    const response = await axios.put(url, body, config);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
                    
      localStorage.removeItem('authToken');        
      window.location.href = '/login';  
    } else {
      console.error("Erro ao enviar dados para API:", error);
    }

    throw error;      
  }
};