import React from 'react';
import { Form, Input, Col, Row, Button, Select, InputNumber, Switch } from 'antd';  // Importando o Select
import { DynamicFormProps, FieldConfig } from '../../types/dynamicForm';
import { maskCNPJ } from './validations/cnpjValidation';
import { maskCPF } from './validations/cpfValidation';
import { maskCEP } from './validations/cepValidation';
import { fetchAddressByCEP } from './service/viacep';
import { fetchCompanyByCNPJOnReceita } from './service/receitaws';
import { maskTelefone } from './validations/telefoneValidation';
import { FaSave } from 'react-icons/fa';
import TextArea from 'antd/es/input/TextArea';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';



const DynamicForm: React.FC<DynamicFormProps> = ({ fields, form, onFinish, initialValues }) => {

  React.useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }    
  }, [form, initialValues, fields]);
    
  React.useEffect(() => {
  }, [form]);

  const renderInput = (field: FieldConfig) => {
      
    if (field.type === 'select' && field.options) {
      
      return (

        <Select
          defaultValue={field.initialValue} // Obtém o valor atual do campo        
          onChange={(value) => {
            form.setFieldsValue({ [field.name]: value }); // Atualiza o valor do formulário quando o usuário seleciona uma opção
          }}
          options={field.options}
          placeholder={field.placeholder || ''}
        />

      );
    }

    if (field.type === 'textarea' ) {
      
      return (

        <TextArea
          showCount
          maxLength={100}
          // onChange={(value) => {
          //   form.setFieldsValue({ [field.name]: value }); // Atualiza o valor do formulário quando o usuário seleciona uma opção
          // }}        
          placeholder={field.placeholder || ''}
          style={{ height: 120, resize: 'none' }}
          defaultValue={field.initialValue} // Obtém o valor atual do campo        
        />

      );
    }
    
    if (field.type === 'money_number' ) {
      
      return (         
          <InputNumber 
            prefix="R$" 
            style={{ width: '100%' }} 
            defaultValue={field.initialValue} // Obtém o valor atual do campo                    
            controls={false}
            decimalSeparator={','}
            />
      );
    }

    if (field.type === 'number' ) {
      
      return (         
          <InputNumber 
            // prefix="R$" 
            style={{ width: '100%' }} 
            defaultValue={field.initialValue} // Obtém o valor atual do campo                    
            controls={false}
            decimalSeparator={','}
            />
      );
    }

    if (field.type === 'switch') {
      return (
        // <Form.Item
        //   name={field.name}
        //   valuePropName="checked" // Mapeia o valor do campo para o atributo 'checked' do Switch
        //   label={field.label}
        // >
          <Switch
            onChange={(checked) => {
              form.setFieldsValue({ [field.name]: checked }); // Atualiza o valor no formulário
            }}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        // </Form.Item>
      );
    }
    


    

    // Se o tipo não for 'select', continua para o switch dos inputs
    switch (field.name) {
      case 'cnpj':
        return (
          <Input
            placeholder={field.placeholder || ''}
            onChange={async (e) => {
              const cnpj = maskCNPJ(e.target.value);
              form.setFieldsValue({ [field.name]: cnpj });

              if (cnpj.replace(/\D/g, '').length === 14) {
                const companyData = await fetchCompanyByCNPJOnReceita(cnpj.replace(/\D/g, ''));
                if (companyData) {
                  form.setFieldsValue({
                    razao_social: companyData.nome,
                    company_name: companyData.nome,
                    nome_fantasia: companyData.fantasia || '',
                    trading_name: companyData.fantasia || '',

                    logradouro: companyData.logradouro,
                    address: companyData.logradouro,

                    bairro: companyData.bairro,
                    neighborhood: companyData.bairro,

                    cidade: companyData.municipio,
                    city: companyData.municipio,

                    estado: companyData.uf,
                    state: companyData.uf,

                    cep: companyData.cep.replace('.', ''),

                    country: 'Brasil'


                    // billing_address: companyData.logradouro + ' ' +


                  });
                }
              }
            }}
          />
        );

      case 'cpf':
        return (
          <Input
            placeholder={field.placeholder || ''}
            onChange={(e) => {
              form.setFieldsValue({ [field.name]: maskCPF(e.target.value) });
            }}
          />
        );

        case 'cep':
          return (
            <Input
              placeholder={field.placeholder || ''}
              onChange={async (e) => {
                const cep = maskCEP(e.target.value);
                form.setFieldsValue({ [field.name]: cep });
  
                if (cep.replace(/\D/g, '').length === 8) {
                  const address = await fetchAddressByCEP(cep.replace(/\D/g, ''));
                  if (address) {
                    form.setFieldsValue({
                      logradouro: address.logradouro,
                      bairro: address.bairro,
                      cidade: address.localidade,
                      estado: address.uf,
  
                      address: address.logradouro,
                      neighborhood: address.bairro,
                      city: address.localidade,
                      state: address.uf,
  
  
                      
                      address_street: address.logradouro   ,                   
                      address_neighborhood: address.bairro,
                      address_city: address.localidade,
                      address_state: address.uf,
      
  
                      country: 'Brasil'
                    });
                  }
                }
              }}
            />
          );

      case 'password':
        return <Input.Password placeholder={field.placeholder || ''} />;

      case 'email':
        return <Input type="email" placeholder={field.placeholder || ''} />;

      // case 'hidden':
      //   return <Input type="text" placeholder={field.placeholder || ''} />;

      case 'number':
        return <Input type="number" placeholder={field.placeholder || ''} />;

      case 'money_number':
        return <Input type="number" placeholder={field.placeholder || ''} />;        

            
        

      case 'telefone':
        return (
          <Input
            placeholder={field.placeholder || ''}
            onChange={(e) => {
              form.setFieldsValue({ [field.name]: maskTelefone(e.target.value) });
            }}
          />
        );

      default:
        return <Input placeholder={field.placeholder || ''} />;
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
    <Row gutter={16}>
      {fields.map((field: FieldConfig) => {
        if (field.showCondition && !field.showCondition(form)) {
          return null;
        }
        return (
          <Col key={field.name} span={field.span || 24}>
            <Form.Item
              name={field.name}
              label={field.label}
              rules={[
                ...(field.required ? [{ required: true, message: `Por favor, insira ${field.label.toLowerCase()}` }] : []),
                ...(field.rules || []),
              ]}
            >
              {renderInput(field)}
            </Form.Item>
          </Col>
        );
      })}
    </Row>
    <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button icon={<FaSave />} type="primary" htmlType="submit">
        Salvar
      </Button>
    </Row>
  </Form>
  );
};

export default DynamicForm;
