export const validateCPF = (_: any, value: string) => {
    const cpf = value.replace(/[^\d]+/g, ''); // Remove qualquer caractere não numérico
    if (cpf.length !== 11) {
      return Promise.reject('CPF inválido');
    }
    // Simples validação de CPF, pode ser expandida com regras mais complexas
    return Promise.resolve();
  };
  
export const maskCPF = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})$/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};