// src/pages/Roles.tsx
import React, { useEffect, useState } from "react";
import { Card, Table, Button,  Modal, Input, Form, Popconfirm, Row, Col, Typography } from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Role } from "../../../../types/role.d"; // Importando o tipo Role
import "./style.scss";
import { fetchData, apiPost, apiPut, apiDelete } from '../../../../services/api';
import { useNotification } from '../../../../components/Notification'; 
import { useBreadcrumbs } from "../../../../components/Breadcrumbs/context/BreadcrumbsContext";
import { DollarCircleOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const Plan: React.FC = () => {
  const { openNotification } = useNotification();
  const { setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    setBreadcrumbs([
      { label: 'Home', link: '/' },
      { label: 'Configurações' },
      { label: 'Permissões' },
    ]);
  }, [setBreadcrumbs]);


  return (
    <>
       <Card
          style={{ width: 300, textAlign: 'center', borderRadius: 8, boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }}
          hoverable
        >
          <Row justify="center" align="middle" style={{ marginBottom: 16 }}>
            <Col>
              <DollarCircleOutlined style={{ fontSize: '48px', color: '#1890ff' }} />
            </Col>
          </Row>

          <Row justify="center" align="middle">
            <Col>
              <Title level={3}>Plano básico</Title>
              <Text type="secondary">
                Ativo até 12/12/2024
              </Text>
              <Title level={2} style={{ marginTop: 16 }}>
                R$ {(49.90).toFixed(2)} mensal
              </Title>
            </Col>
          </Row>
        </Card>
    </>
  );
};

export default Plan;
