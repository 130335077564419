export const FormSupplier = [
  {
    name: 'cnpj',
    label: 'CNPJ',
    placeholder: 'CNPJ do fornecedor',
    span: 6,
    required: true,
    rules: [{ required: true, message: 'Por favor, insira o CNPJ' }],
  },
  {
    name: 'company_name',
    label: 'Razão Social',
    placeholder: 'Nome da empresa',
    span: 9,
    required: true,
    rules: [{ required: true, message: 'Por favor, insira o nome da empresa' }],
  },
  {
    name: 'trading_name',
    label: 'Nome Fantasia',
    placeholder: 'Nome fantasia da empresa',
    span: 9,
  },
  {
    name: 'state_registration',
    label: 'Inscrição Estadual',
    placeholder: 'Inscrição estadual do fornecedor',
    span: 5,
  },
  {
    name: 'municipal_registration',
    label: 'Inscrição Municipal',
    placeholder: 'Inscrição municipal do fornecedor',
    span: 5,
  },
  {
    name: 'supplier_phone',
    label: 'Telefone da Empresa',
    placeholder: 'Telefone do fornecedor',
    span: 6,
  },
  {
    name: 'supplier_email',
    label: 'Email da Empresa',
    placeholder: 'Email do fornecedor',
    span: 8,
    rules: [{ type: 'email', message: 'Por favor, insira um email válido' }],
  },
  {
    name: 'responsible_name',
    label: 'Nome do Responsável',
    placeholder: 'Nome do responsável pelo fornecedor',
    span: 10,
    required: true,
    rules: [{ required: true, message: 'Por favor, insira o nome do responsável' }],
  },
  {
    name: 'responsible_phone',
    label: 'Telefone do Responsável',
    placeholder: 'Telefone do responsável',
    span: 6,
  },
  {
    name: 'responsible_email',
    label: 'Email do Responsável',
    placeholder: 'Email do responsável',
    span: 8,
    rules: [{ type: 'email', message: 'Por favor, insira um email válido' }],
  },
  {
    name: 'cep',
    label: 'CEP',
    placeholder: 'CEP do fornecedor',
    span: 4,
    required: true,
    rules: [{ required: true, message: 'Por favor, insira o CEP' }],
  },
  
  {
    name: 'address_street',
    label: 'Rua',
    placeholder: 'Rua do fornecedor',
    span: 9,
    required: true,
    rules: [{ required: true, message: 'Por favor, insira a rua' }],
  },
  {
    name: 'address_number',
    label: 'Número',
    placeholder: 'Número do endereço',
    span: 4,
  },
  {
    name: 'address_complement',
    label: 'Complemento',
    placeholder: 'Complemento do endereço',
    span: 7,
  },
  {
    name: 'address_neighborhood',
    label: 'Bairro',
    placeholder: 'Bairro do fornecedor',
    span: 5,
  },
  {
    name: 'address_city',
    label: 'Cidade',
    placeholder: 'Cidade do fornecedor',
    span: 6,
    required: true,
    rules: [{ required: true, message: 'Por favor, insira a cidade' }],
  },
  {
    name: 'address_state',
    label: 'Estado',
    placeholder: 'Estado do fornecedor',
    span: 5,
    required: true,
    rules: [{ required: true, message: 'Por favor, insira o estado' }],
  },
  
  {
    name: 'supplier_type',
    label: 'Tipo de Fornecedor',
    type: 'select',
    placeholder: 'Selecione o tipo de fornecedor',
    span: 4,
    options: [
      { label: 'Produto', value: 'produto' },
      { label: 'Serviço', value: 'serviço' },
    ],
    required: true,
    rules: [{ required: true, message: 'Por favor, selecione o tipo de fornecedor' }],
  },


  
  {
    name: 'address_country',
    label: 'Pais',
    placeholder: 'Brasil',
    span: 4,
    type: 'text',
  },
  {
    name: 'credit_limit',
    label: 'Limite de Crédito',
    placeholder: 'Limite de crédito do fornecedor',
    span: 6,
    type: 'money_number',
  },
  {
    name: 'monthly_billing',
    label: 'Faturamento Mensal',
    placeholder: 'Faturamento mensal do fornecedor',
    span: 6,
    type: 'money_number',
  },
  {
    name: 'payment_term_days',
    label: 'Prazo de Pagamento',
    placeholder: 'Prazo de pagamento em dias',
    span: 6,
    type: 'number',
  },
  {
    name: 'payment_method',
    label: 'Método de Pagamento',
    placeholder: 'Método de pagamento preferencial',
    span: 6,
  },
  {
    name: 'commercial_references',
    label: 'Referências Comerciais',
    placeholder: 'Referências comerciais',
    span: 12,
    type: 'textarea',
  },
  {
    name: 'notes',
    label: 'Observações',
    placeholder: 'Notas e observações',
    span: 12,
    type: 'textarea',
  },
  {
    name: 'is_active',
    label: 'Ativo',
    type: 'switch',
    valuePropName: 'checked',
    placeholder: 'Fornecedor ativo ou inativo',
    span: 4,
  },
];
