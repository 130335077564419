// src/pages/Home.tsx
import React from "react";
import DashboardLayout from "../../components/LayoutPainel";
import { Typography } from "antd";
import  "./style.scss";
const { Title } = Typography;

const Home: React.FC = () => {
  return (
    <DashboardLayout>
      <Title level={2}>Estoque</Title>
      
    </DashboardLayout>
  );
};

export default Home;
