// src/context/BreadcrumbsContext.tsx

import React, { createContext, useContext, useState, ReactNode } from 'react';
import { BreadcrumbItem, BreadcrumbsContextType } from '../../../types/breadcrumbs';


// Inicialmente, o contexto terá um valor default vazio
const BreadcrumbsContext = createContext<BreadcrumbsContextType | undefined>(undefined);

// Provider para o contexto dos breadcrumbs
interface BreadcrumbsProviderProps {
  children: ReactNode;
}

export const BreadcrumbsProvider: React.FC<BreadcrumbsProviderProps> = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);

  return (
    <BreadcrumbsContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>
      {children}
    </BreadcrumbsContext.Provider>
  );
};

// Hook personalizado para usar o contexto dos breadcrumbs
export const useBreadcrumbs = (): BreadcrumbsContextType => {
  const context = useContext(BreadcrumbsContext);
  if (!context) {
    throw new Error('useBreadcrumbs must be used within a BreadcrumbsProvider');
  }
  return context;
};
