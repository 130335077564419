import React, { useContext, useMemo } from 'react';
import { notification } from 'antd';
import type { NotificationArgsProps } from 'antd';

type NotificationPlacement = NotificationArgsProps['placement'];

interface NotificationContextProps {
  openNotification: (type:string, message: string, description: string, placement: NotificationPlacement) => void;
}

const NotificationContext = React.createContext<NotificationContextProps | null>(null);

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (type: string, message: string, description: string, placement: NotificationPlacement) => {

    switch (type) {
      case 'info':
        api.info({
          message,
          description,
          placement,
        });    
        break;

        case 'error':
          api.error({
            message,
            description,
            placement,
          });    
          break;

        case 'success':
          api.success({
            message,
            description,
            placement,
          });    
          break;

      default:
        break;
    }

    
  };

  const contextValue = useMemo(() => ({ openNotification }), [api]);

  return (
    <NotificationContext.Provider value={contextValue}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};
