// src/pages/Home.tsx
import React, { useEffect } from "react";
import DashboardLayout from "../../components/LayoutPainel";
import { Typography } from "antd";
import { useBreadcrumbs } from "../../components/Breadcrumbs/context/BreadcrumbsContext";

const { Title } = Typography;

const Home: React.FC = () => {

  const { setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    setBreadcrumbs([
      { label: 'Home', link: '/dashboard' },
      
    ]);
  }, [setBreadcrumbs]);

  return (
    <DashboardLayout>
      <Title level={2}>Bem-vindo ao Dashboard do ERP</Title>
      <p>Esta é a página inicial do seu sistema ERP.</p>
    </DashboardLayout>
  );
};

export default Home;
