// src/pages/Roles.tsx
import React, { useEffect, useState } from "react";
import { Card, Table, Button,  Modal, Input, Form, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Role } from "../../../../types/role.d"; // Importando o tipo Role
import "./style.scss";
import { fetchData, apiPost, apiPut, apiDelete } from '../../../../services/api';
import { useNotification } from '../../../../components/Notification'; 
import { useBreadcrumbs } from "../../../../components/Breadcrumbs/context/BreadcrumbsContext";



const Roles: React.FC = () => {
  const { openNotification } = useNotification();
  const { setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    setBreadcrumbs([
      { label: 'Home', link: '/' },
      { label: 'Configurações' },
      { label: 'Permissões' },
    ]);
  }, [setBreadcrumbs]);

  const [isTableLoading, setIsTableLoading] = useState(true);
  const [roles, setRoles] = useState<Role[]>([]);  

  const fetchRoleData = async () => {    
    try {
      const data = await fetchData('v1/roles');

      setRoles(data.data.map((role: Role) => {
        return { ...role, key: role.id };
      }))
      setIsTableLoading(false)
    } catch (error) {
      console.error("Erro ao buscar dados do role:", error);
    }
  };


  useEffect(() => {
    fetchRoleData();
  },[]);     
  
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingRole, setEditingRole] = useState<Role | null>(null);
  const [form] = Form.useForm();

  const handleAddRole = () => {
    setEditingRole(null);
    setIsModalVisible(true);
  };

  const handleEditRole = (Role: Role) => {
    setEditingRole(Role);
    form.setFieldsValue(Role);
    setIsModalVisible(true);
  };

  const handleDeleteRole = async (id: number) => {
    try {
      setIsTableLoading(true);
      await apiDelete(`v1/roles/${id}`);
      setRoles(roles.filter((role) => role.id !== id));
      openNotification('success', 'Sucesso!', 'Permissão excluída com sucesso!', 'topRight');
    } catch (error: any) {
      openNotification('error', 'Erro ao excluir permissão!', error.response.data.message, 'topRight');
    } finally {
      setIsTableLoading(false);
    }
  };

  const handleSaveRole = async (values: Role) => {
    setIsTableLoading(true);
  
    if (editingRole) {
      try {
        
        await apiPut(`v1/roles/${editingRole.id}`, values);
        setRoles(roles.map((role) => (role.id === editingRole.id ? { ...role, ...values } : role)));
        openNotification('success', 'Sucesso', 'Permissão alterada com sucesso!', 'topRight');
      } catch (error: any) {
        openNotification('error', 'Erro ao modificar permissão!', error.response.data.message, 'topRight');
      }
    } else {
      try {
        const newRole: Role = { ...values, key: roles.length + 1 };
        await apiPost('v1/roles', newRole);
        setRoles([...roles, newRole]);
        openNotification('success', 'Sucesso!', 'Permissão criada com sucesso!', 'topRight');
      } catch (error: any) {
        openNotification('error', 'Erro ao criar permissão!', error.response.data.message, 'topRight');
      }
    }
    
    setIsModalVisible(false);
    form.resetFields();
    setIsTableLoading(false);
  };

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
    },   
    {
      title: "Ações",
      key: "actions",
      render: (_: any, role: Role) => (
        <>
          <Button icon={<EditOutlined />} onClick={() => handleEditRole(role)} style={{ marginRight: 8 }} />
          <Popconfirm
            title="Tem certeza que deseja excluir?"
            onConfirm={() => handleDeleteRole(role.id)}
            okText="Sim"
            cancelText="Não"
          >
            <Button danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <Card title="Permissões" extra={<Button icon={<PlusOutlined />} type="primary" onClick={handleAddRole}>Adicionar</Button>}>
        <Table dataSource={roles} columns={columns} loading={isTableLoading}/>
      </Card>

      <Modal
        title={editingRole ? "Editar Role" : "Adicionar Role"}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => form.submit()}
      >
        <Form form={form} layout="vertical" onFinish={handleSaveRole}>
          <Form.Item name="name" label="Nome" rules={[{ required: true, message: "Por favor, insira o nome da permissão" }]}>
            <Input placeholder="Nome do permissão" />
          </Form.Item>

        </Form>
      </Modal>
    </>
  );
};

export default Roles;
