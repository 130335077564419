import { fetchData } from "../../../services/api";

export const fetchCompanyByCNPJOnReceita = async (cnpj: string) => {
    try {
      const formattedCNPJ = cnpj.replace(/\D/g, ''); // Remove pontos e traços do CNPJ
      const endpoint = `v1/utils/cnpj/${formattedCNPJ}`;          
      const data = await fetchData(endpoint);
    
      if (data.status === 'ERROR') {
        throw new Error('CNPJ não encontrado ou inválido');
      }
      return data;
    } catch (error) {
      console.error('Erro ao buscar o CNPJ:', error);
      return null;
    }
  };
  