import React, { useEffect, useState } from "react";
import { Card, Table, Button, Typography, Modal, Form, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { User } from "../../../../types/user"; 
import "./style.scss";
import { fetchData, apiPost, apiDelete, apiPut } from '../../../../services/api';
import { useNotification } from '../../../../components/Notification'; 
import { useBreadcrumbs } from "../../../../components/Breadcrumbs/context/BreadcrumbsContext";
import DynamicForm from "../../../../components/DynamicForm";
import { Role } from "../../../../types/role";

const { Title } = Typography;

const MyUserd: React.FC = () => {
  const { openNotification } = useNotification();

  const { setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    setBreadcrumbs([
      { label: 'Home', link: '/' },
      { label: 'Configurações' },
      { label: 'Meus usuários' },
    ]);
  }, [setBreadcrumbs]);

  const [isTableLoading, setIsTableLoading] = useState(true);
  const [users, setUsers] = useState<User[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [pagination, setPagination] = useState({
    current: 1,  
    pageSize: 20, 
    total: 0,    
  });

  const fetchUserData = async (page: number = 1, pageSize: number = 2) => {    
    try {
      setIsTableLoading(true);
      const data = await fetchData(`v1/users?page=${page}&per_page=${pageSize}`);

      setUsers(data.data.map((user: User) => {
        return { ...user, key: user.id };
      }));

      setPagination({
        current: data.current_page,
        pageSize: data.per_page,
        total: data.total,
      });

      setIsTableLoading(false);
    } catch (error) {
      console.error("Erro ao buscar dados do usuário:", error);
      setIsTableLoading(false);
    }
  };

  const fetchRolesData = async () => {    
    try {
      setIsTableLoading(true);
      const data = await fetchData(`v1/roles`);

      setRoles(data.data.map((role: Role) => {
        return { ...role, key: role.id };
      }));

      setIsTableLoading(false);
    } catch (error) {
      console.error("Erro ao buscar dados das permissões:", error);
      setIsTableLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData(pagination.current, pagination.pageSize);
    fetchRolesData();



  }, []);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingUser, setEditingUser] = useState<User | null>(null);
  const [form] = Form.useForm();

  const handleAddUser = () => {
    form.resetFields();
    setEditingUser(null);
    setIsModalVisible(true);
  };

  const handleEditUser = (user: User) => {
    form.resetFields();    
    setEditingUser(user);
    setIsModalVisible(true);
  };

  useEffect(() => {

    if (editingUser) {

      form.setFieldsValue({
        name: editingUser.name,
        email: editingUser.email,
        role_id: editingUser.roles && editingUser.roles.length > 0 ? editingUser.roles[0].id : undefined,
      });

    } else {
      form.resetFields();
    }
  }, [editingUser, form]);

  const handleDeleteUser = async (id: number) => {
    try {
      setIsTableLoading(true);
      await apiDelete(`v1/users/${id}`);
      setUsers(users.filter((user) => user.id !== id));
      openNotification('success', 'Sucesso!', 'Usuário excluído com sucesso!', 'topRight');
    } catch (error: any) {
      openNotification('error', 'Erro ao excluir usuário!', error.response.data.message, 'topRight');
    } finally {
      setIsTableLoading(false);
    }
  };

  const handleSaveUser = async (values: User) => {
    setIsTableLoading(true);
  
    
    if (editingUser) {
      try {
        await apiPut(`v1/users/${editingUser.id}`, values);
        setUsers(users.map((user) => (user.id === editingUser.id ? { ...user, ...values } : user)));
        openNotification('success', 'Sucesso', 'Usuário alterado com sucesso!', 'topRight');
      } catch (error: any) {
        openNotification('error', 'Erro ao modificar usuário!', error.response.data.message, 'topRight');
      }
    } else {
      try {
        const newUser: User = { ...values, key: users.length + 1 };
        await apiPost('v1/users/register', newUser);
        setUsers([...users, newUser]);
        openNotification('success', 'Novo Usuário', 'Usuário criado com sucesso!', 'topRight');
      } catch (error: any) {
        openNotification('error', 'Erro ao criar usuário!', error.response.data.message, 'topRight');
      }
    }
    
    setIsModalVisible(false);
    form.resetFields();
    setIsTableLoading(false);
  };

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Permissão",
      key: "role",
      render: (_: any, user: User) => (
        <>
          {/* Exibe as permissões do usuário (mapeando as roles) */}
          {user.roles && user.roles.length > 0 ? (
            <span>{user.roles.map((role) => role.name).join(', ')}</span>
          ) : (
            <span>Sem permissões</span>
          )}
        </>
      ),
    },
    {
      title: "Ações",
      key: "actions",
      render: (_: any, user: User) => (
        <>
          <Button icon={<EditOutlined />} onClick={() => handleEditUser(user)} style={{ marginRight: 8 }} />
          <Popconfirm
            title="Tem certeza que deseja excluir?"
            onConfirm={() => handleDeleteUser(user.id)}
            okText="Sim"
            cancelText="Não"
          >
            <Button danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </>
      ),
    },
  ];

  const handleTableChange = (pagination: any) => {
    fetchUserData(pagination.current, pagination.pageSize);
  };

  const fields = [
    {
      name: 'name',
      label: 'Nome',
      placeholder: 'Nome do usuário',
      required: true,
      rules: [{ required: true, message: 'Por favor, insira o nome' }],
    },
    {
      name: 'email',
      label: 'Email',
      placeholder: 'Email do usuário',
      required: true,
      rules: [{ required: true, message: 'Por favor, insira o email' }],
    },
    {
      name: 'role_id',
      label: 'Role',
      type: 'select',
      placeholder: 'Selecione uma role',
      options: roles.map((role) => ({
        label: role.name,
        value: role.id,
      })),
      required: true,
      rules: [{ required: true, message: 'Por favor, selecione uma role' }],      
    }
  ];

  return (
    <>
      <Card title="Meus usuários" extra={<Button icon={<PlusOutlined />} type="primary" onClick={handleAddUser}>Adicionar</Button>}>
        <Table 
          dataSource={users} 
          columns={columns} 
          loading={isTableLoading}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
          }}
          onChange={handleTableChange}  
        />
      </Card>

      <Modal
        title={editingUser ? "Editar Usuário" : "Adicionar Usuário"}
        open={isModalVisible} 
        afterClose={() => {  
            setEditingUser(null);
            form.resetFields();
          }       
        }
        onCancel={() => {          
          setIsModalVisible(false);
        }}
        footer={null}
      >
        <DynamicForm fields={fields} form={form} onFinish={handleSaveUser} handleSave={handleSaveUser} />
      </Modal>
    </>
  );
};

export default MyUserd;
