// src/components/DashboardLayout.tsx
import React from "react";
import { Layout, Menu, Dropdown, Avatar } from "antd";
import { HomeOutlined, SettingOutlined, UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import SidebarMenu from "../SidebarMenu";
import './style.scss';
import Breadcrumbs from "../Breadcrumbs";
import { useBreadcrumbs } from "../Breadcrumbs/context/BreadcrumbsContext";

const { Header,  Content } = Layout;

const DashboardLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();

  const handleMenuClick = (e: { key: string }) => {
    if (e.key === "logout") {
      localStorage.removeItem("authToken");
      navigate("/login");
    }
  };

  const { breadcrumbs } = useBreadcrumbs(); 

 
  const userMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="profile" icon={<UserOutlined />}>
        <Link to="/dashboard/profile">Editar Perfil</Link>
      </Menu.Item>
      <Menu.Item key="settings" icon={<SettingOutlined />}>
        <Link to="/dashboard/settings">Configurações</Link>
      </Menu.Item>
      <Menu.Item key="logout" icon={<LogoutOutlined />}>
        Sair
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {/* Sidebar fixa */}
      <SidebarMenu />

      {/* Layout principal */}
      <Layout>
        {/* Navbar com o menu de usuário */}
        <Header className="site-layout-background" style={{ padding: 0, background: "#fff", display: "flex", justifyContent: "flex-end", alignItems: "center", paddingRight: 20 }}>
          <Link to="/logout" className="btn-logout">Sair</Link>
        </Header>

        {/* Conteúdo dinâmico */}
        <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
        <Breadcrumbs items={breadcrumbs} /> {/* Breadcrumbs agora utiliza o contexto */}

          <div style={{ padding: 24, background: "#fff", minHeight: 360 }}>{children}</div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
