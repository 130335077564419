export const validateCNPJ = (_: any, value: string) => {
    const cnpj = value.replace(/[^\d]+/g, ''); // Remove qualquer caractere não numérico

    
    if (cnpj.length !== 14) {

      return Promise.reject('CNPJ inválido');
    }
    // Simples validação de CNPJ, pode ser expandida com regras mais complexas
    return Promise.resolve();
  };
  
export const maskCNPJ = (value: string) => {
  return value
    .replace(/\D/g, '') // Remove todos os caracteres que não são números
    .replace(/^(\d{2})(\d)/, '$1.$2') // Adiciona o primeiro ponto
    .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3') // Adiciona o segundo ponto
    .replace(/\.(\d{3})(\d)/, '.$1/$2') // Adiciona a barra
    .replace(/(\d{4})(\d)/, '$1-$2') // Adiciona o traço
    .replace(/(-\d{2})\d+?$/, '$1'); // Remove caracteres excedentes
};