// src/pages/Home.tsx
import React, { useState } from "react";
import { Layout, Typography, Tabs } from "antd";

import MyUsers from "../../subpages/MyUsers";
import CompanyData from "../../subpages/CompanyData";
import Plan from "../../subpages/Plan";


import  "./style.scss";
import Roles from "../../subpages/Roles";
const { Title } = Typography;
const { Sider, Content } = Layout;
const { TabPane } = Tabs;

const Subpages: React.FC = () => {
  const [activeTab, setActiveTab] = useState("1");

  const renderContent = () => {
    switch (activeTab) {
      case "1":
        return <MyUsers />;
      case "2":
        return <CompanyData />;
      case "3":
        return <Plan />;
      case "4":
        return <CompanyData />;
      case "5":
        return <CompanyData />;
      case "6":
        return <Roles />;
      case "7":
        return <CompanyData />;
      
      default:
        return <MyUsers />;
    }
  };

  return (
    
      <Layout>
        
        <Sider width={165}  className="subpagessider_" theme="light">
          <Tabs
            defaultActiveKey="1"
            onChange={(key) => setActiveTab(key)}
            tabPosition="left"
            
          >
            <TabPane tab="Meus usuários" key="1" />
            <TabPane tab="Dados da empresa" key="2" />
            <TabPane tab="Meu plano" key="3" />
            <TabPane tab="Nota fiscal" key="4" />
            <TabPane tab="SMTP" key="5" />
            <TabPane tab="Permissões" key="6" />
            
          </Tabs>
        </Sider>

        <Content style={{ padding: "0 24px" }}>          
          <div>{renderContent()}</div>
        </Content>

      </Layout>
    
  );
};

export default Subpages;
