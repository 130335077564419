// src/services/authService.ts
import axios from "axios";

// Função de login para enviar credenciais à API
export const login = async (email: string, password: string) => {
  try {
    const apiUrl = process.env.REACT_APP_API_URL + '/login';
    const response = await axios.post(apiUrl, {
      email,
      password,
    });
    return response.data;
  } catch (error) {
    throw new Error("Erro ao fazer login. Verifique suas credenciais.");
  }
};
