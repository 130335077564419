import React from 'react';
import { Form } from 'antd';
import DynamicForm from '../../../../../../components/DynamicForm';
import { DynamicFormData,  FieldConfig } from '../../../../../../types/dynamicForm';
import { Company } from '../../../../../../types/company';
import { apiPut } from '../../../../../../services/api';
import { useNotification } from '../../../../../../components/Notification';

const CompanyForm: React.FC<DynamicFormData> = ({ data }) => {
  const [form] = Form.useForm();

  const { openNotification } = useNotification();

  const fields: FieldConfig[] = [
    {
      name: 'cnpj',
      label: 'CNPJ',
      placeholder: 'Insira o CNPJ',
      required: true,
      rules: [{ len: 18, message: 'CNPJ deve conter 14 dígitos' }],
      span: 4,
    },
    {
      name: 'inscricao_estadual',
      label: 'Inscrição Estadual',
      placeholder: 'Insira a inscrição estadual (opcional)',
      required: false,
      rules: [{ max: 20, message: 'Máximo 20 caracteres' }],
      span: 6,
    },
    {
      name: 'inscricao_municipal',
      label: 'Inscrição Municipal',
      placeholder: 'Insira a inscrição municipal (opcional)',
      required: false,
      rules: [{ max: 20, message: 'Máximo 20 caracteres' }],
      span: 6,
    },
    {
      name: 'razao_social',
      label: 'Razão Social',
      placeholder: 'Insira a razão social',
      required: true,
      span: 12,
    },
    {
      name: 'nome_fantasia',
      label: 'Nome Fantasia',
      placeholder: 'Insira o nome fantasia',
      required: true,
      span: 12,
    },
    {
      name: 'cep',
      label: 'CEP',
      placeholder: 'Insira o CEP',
      required: true,
      rules: [{ len: 9, message: 'CEP deve conter 8 dígitos' }],
      span: 4,
    },
    {
      name: 'logradouro',
      label: 'Logradouro',
      placeholder: 'Insira o logradouro',
      required: true,
      span: 7,
    },
    {
      name: 'numero',
      label: 'Número',
      placeholder: 'Insira o número',
      required: true,
      rules: [{ max: 10, message: 'Máximo 10 caracteres' }],
      span: 3,
    },
    {
      name: 'bairro',
      label: 'Bairro',
      placeholder: 'Insira o bairro',
      required: true,
      rules: [{ max: 100, message: 'Máximo 100 caracteres' }],
      span: 4,
    },
    {
      name: 'complemento',
      label: 'Complemento',
      placeholder: 'Insira o complemento (opcional)',
      required: false,
      span: 6,
    },
    {
      name: 'estado',
      label: 'Estado',
      placeholder: 'Insira a sigla do estado',
      required: true,
      rules: [{ len: 2, message: 'Estado deve conter 2 letras' }],
      span: 4,
    },
    {
      name: 'cidade',
      label: 'Cidade',
      placeholder: 'Insira a cidade',
      required: true,
      rules: [{ max: 100, message: 'Máximo 100 caracteres' }],
      span: 4,
    },
    {
      name: 'telefone',
      label: 'Telefone',
      placeholder: 'Insira o telefone',
      required: true,
      rules: [{ max: 15, message: 'Máximo 15 caracteres' }],
      span: 4,
    },
    {
      name: 'email',
      label: 'Email',
      type: 'email',
      placeholder: 'Insira o email',
      required: true,
      rules: [
        { type: 'email', message: 'Por favor, insira um email válido' },
        { max: 255, message: 'Máximo 255 caracteres' },
      ],
      span: 4,
    },
    {
      name: 'website',
      label: 'Website',
      placeholder: 'Insira o website (opcional)',
      required: false,
      rules: [{ max: 255, message: 'Máximo 255 caracteres' }],
      span: 4,
    },
    {
      name: 'status',
      label: 'Status',
      placeholder: 'Insira o status (ativo ou inativo)',
      required: false,
      rules: [{ pattern: /^(ativo|inativo)$/, message: 'Status deve ser ativo ou inativo' }],
      span: 4,
    },
  ];

  const handleSaveCompany = async (values: Company): Promise<void> => {
    try {
      // Envia uma requisição PUT para o endpoint de atualização da empresa
      const response = await apiPut('v1/company', values);
  
      // Exibe mensagem de sucesso ou redireciona o usuário
      openNotification('success', 'Sucesso!', response.message, 'topRight');
      
  
    } catch (error: any) {
      // Lidar com erros na requisição
      openNotification('error', 'Erro!',  error.message , 'topRight');
      
    }
  };

  return <DynamicForm 
                fields={fields} 
                form={form} 
                onFinish={handleSaveCompany} 
                initialValues={data}
                handleSave={function (): void {
                  throw new Error('Function not implemented.');
                } } 
          />;
};

export default CompanyForm;
