// src/components/Login.tsx
import React, { useState } from "react";
import { Form, Input, Button, message, Typography, Image } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { login } from "../../services/authService";
import { useNavigate } from "react-router-dom";

import logo from "../../images/logo.png";

import  "./style.scss";


const { Title } = Typography;

const Login: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Hook do React Router para navegação

  const onFinish = async (values: { username: string; password: string }) => {
    setLoading(true);
    try {
      const response = await login(values.username, values.password);

      // Se o login for bem-sucedido e um token for retornado
      if (response) {

        message.success("Login realizado com sucesso!");
        localStorage.setItem("authToken", response.access_token);
        navigate("/dashboard");

      } else {
        message.error("Usuário ou senha incorretos.");
      }
    } catch (error) {
      message.error("Erro ao fazer login. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  return (
   
    <div className="login-wrapper">
    <div className="login-box">
       <div className="logo-img" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 0 40px -16px' }}>
         <Image src={logo} alt="Logo" style={{ width: 200 }} preview={false} />
       </div>
    <Form
        name="login_form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        className="form"
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: "Por favor, insira seu usuário!" }]}
        >
          <Input
            prefix={<UserOutlined />}
            placeholder="Usuário"
            size="large"
            disabled={loading}
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: "Por favor, insira sua senha!" }]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            placeholder="Senha"
            size="large"
            disabled={loading}
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            style={{ width: "100%" }}
            loading={loading}
            className="btn-submit"
          >
            Entrar
          </Button>
        </Form.Item>
      </Form>
    </div>
  </div>
  );
};

export default Login;
