import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import './styles/main.scss';
import Login from "./pages/Login"; 

import Dashboard from "./pages/Dashboard"; 
import Config from "./pages/Config"; 
import Stock from "./pages/Stock"; 
import PrivateRoute from "./components/PrivateRoute"; 
import Logout from "./components/Logout";
import Clients from "./pages/Clients";

import Suppliers from "./pages/Suppliers";


const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        {/* Rota de Login */}
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />


        {/* Rotas do Dashboard, protegidas */}
        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={<Dashboard />}/>

          <Route path="/clients" element={<Clients />}/>
          <Route path="/config" element={<Config />}/>
          <Route path="/stock" element={<Stock />}/>

          <Route path="/suppliers" element={<Suppliers />}/>

          
        </Route>

        {/* Redireciona para login se nenhuma rota for encontrada */}
        <Route path="*" element={<Login />} />

      </Routes>
    </Router>
  );
};

export default App;
