// src/components/PrivateRoute.tsx
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

// Função que verifica se o usuário está autenticado
const isAuthenticated = () => {
  // Verifica se há um token no localStorage
  const token = localStorage.getItem("authToken");
  return !!token; // Retorna true se o token existir
};

const PrivateRoute: React.FC = () => {
  return isAuthenticated() ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
