import React, { useEffect, useState } from "react";
import { Card, Col, Row, Table, Typography, Button, Modal, Form, Input, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import DashboardLayout from "../../components/LayoutPainel";
import { useBreadcrumbs } from "../../components/Breadcrumbs/context/BreadcrumbsContext";
import DynamicForm from "../../components/DynamicForm";
import { fetchData, apiPost, apiPut, apiDelete } from "../../services/api";
import { Supplier } from '../../types/supplier';
import { FormSupplier } from './forms/Supplier'; // Campos do formulário de fornecedores
import { useNotification } from "../../components/Notification";

const { Title } = Typography;
const { Search } = Input;

const Suppliers: React.FC = () => {
  const { setBreadcrumbs } = useBreadcrumbs();
  const { openNotification } = useNotification();

  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [editingSupplier, setEditingSupplier] = useState<Supplier | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    setBreadcrumbs([
      { label: 'Home', link: '/dashboard' },
      { label: 'Fornecedores', link: '/suppliers' },
    ]);

    loadSuppliers();
  }, [setBreadcrumbs, searchTerm]);

  // Função para carregar fornecedores
  const loadSuppliers = async () => {
    setLoading(true);
    try {
      const data = await fetchData(`v1/suppliers?search=${searchTerm}`);
      setSuppliers(data);
    } catch (error) {
      console.error('Erro ao carregar fornecedores:', error);
      openNotification('error', 'Erro', 'Falha ao carregar a lista de fornecedores.', 'topRight');
    }
    setLoading(false);
  };

  // Função para gerenciar a pesquisa
  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  // Abrir modal para adicionar fornecedor
  const handleAddSupplier = () => {
    form.resetFields();
    setEditingSupplier(null);
    setIsModalVisible(true);
  };

  // Abrir modal para editar fornecedor
  const handleEditSupplier = (supplier: Supplier) => {
    form.resetFields();
    setEditingSupplier(supplier);
    setIsModalVisible(true);
    form.setFieldsValue(supplier);
  };

  // Fechar o modal
  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingSupplier(null);
    form.resetFields();
  };

  // Adicionar ou editar fornecedor
  const handleAddOrEditSupplier = async (values: any) => {
    try {
      if (editingSupplier) {
        await apiPut(`v1/suppliers/${editingSupplier.id}`, values);
        openNotification('success', 'Sucesso', 'Fornecedor atualizado com sucesso!', 'topRight');
      } else {
        await apiPost('v1/suppliers', values);
        openNotification('success', 'Sucesso', 'Fornecedor adicionado com sucesso!', 'topRight');
      }
      loadSuppliers();
      setIsModalVisible(false);
    } catch (error: any) {
      console.error('Erro ao salvar fornecedor:', error);
      openNotification('error', 'Erro', error.response?.data?.message || 'Erro ao salvar fornecedor.', 'topRight');
    }
  };

  // Excluir fornecedor
  const handleDeleteSupplier = async (id: number) => {
    try {
      await apiDelete(`v1/suppliers/${id}`);
      openNotification('success', 'Sucesso', 'Fornecedor removido com sucesso!', 'topRight');
      loadSuppliers();
    } catch (error: any) {
      console.error('Erro ao remover fornecedor:', error);
      openNotification('error', 'Erro', error.response?.data?.message || 'Erro ao remover fornecedor.', 'topRight');
    }
  };

  // Colunas da tabela
  const columns = [
    {
      title: 'Razão Social',
      dataIndex: 'company_name',
      key: 'company_name',
    },
    {
      title: 'CNPJ',
      dataIndex: 'cnpj',
      key: 'cnpj',
    },
    {
      title: 'Email',
      dataIndex: 'supplier_email',
      key: 'supplier_email',
    },
    {
      title: 'Responsável',
      dataIndex: 'responsible_name',
      key: 'responsible_name',
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (_: any, supplier: Supplier) => (
        <>
          <Button icon={<EditOutlined />} onClick={() => handleEditSupplier(supplier)} style={{ marginRight: 8 }} />
          <Popconfirm
            title="Tem certeza que deseja excluir?"
            onConfirm={() => handleDeleteSupplier(supplier.id)}
            okText="Sim"
            cancelText="Não"
          >
            <Button danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <Row justify="space-between" align="middle" style={{ marginBottom: 20 }}>
        <Col>
          <Title level={2}>Fornecedores</Title>
        </Col>
        <Col>
          <Button type="primary" icon={<PlusOutlined />} onClick={handleAddSupplier}>
            Adicionar Fornecedor
          </Button>
        </Col>
      </Row>

      {/* Campo de pesquisa */}
      <Row justify="start" style={{ marginBottom: 20 }}>
        <Col span={8}>
          <Search placeholder="Pesquisar fornecedor" onSearch={handleSearch} enterButton />
        </Col>
      </Row>

      {/* Tabela de fornecedores */}
      <Table
        columns={columns}
        dataSource={suppliers}
        rowKey="id"
        loading={loading}
      />

      {/* Modal para adicionar ou editar fornecedor */}
      <Modal
        title={editingSupplier ? "Editar Fornecedor" : "Adicionar Fornecedor"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={1024}
      >
        <DynamicForm
          fields={FormSupplier}
          form={form}
          onFinish={handleAddOrEditSupplier}
          handleSave={handleAddOrEditSupplier}
          initialValues={editingSupplier || {}}
        />
      </Modal>
    </DashboardLayout>
  );
};

export default Suppliers;
