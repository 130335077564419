// src/pages/CompanyData.tsx
import React, { useEffect, useState } from "react";
import { Card, Typography, Form } from "antd";
import { User } from "../../../../types/user"; // Importando o tipo User
import "./style.scss";
import { fetchData } from '../../../../services/api';
import { useNotification } from '../../../../components/Notification'; 
import { useBreadcrumbs } from "../../../../components/Breadcrumbs/context/BreadcrumbsContext";
import CompanyForm from "./forms/CompanyForm";
import { Company } from "../../../../types/company";

const { Title } = Typography;

const CompanyData: React.FC = () => {
  const { openNotification } = useNotification();
  const { setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    setBreadcrumbs([
      { label: 'Home', link: '/' },
      { label: 'Configurações' },
      { label: 'Dados da empresa' },
    ]);
  }, [setBreadcrumbs]);

  
  const [isLoading, setIsLoading] = useState(false);

  const [company, setCompany] = useState<Company>();  
  const fetchCompanyData = async () => {    
    try {
      const data = await fetchData('v1/company');
      setCompany(data)
      
      setIsLoading(false)
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  };

  useEffect(() => {
    fetchCompanyData();
    console.log(company);
  },[]);

  
  return (
    <>
      <Card title="Dados da Empresa" extra='' loading={isLoading}>      
        <CompanyForm data={company}/>

      </Card>

      
      
    </>
  );
};

export default CompanyData;
