// src/components/Breadcrumbs.tsx

import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom'; // Opcional, se estiver usando react-router-dom
import { BreadcrumbsProps } from '../../types/breadcrumbs';


const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items }) => {
  return (
    <Breadcrumb style={{ margin: '-6px 0 16px ' }}>
      {items.map((item, index) => (
        <Breadcrumb.Item key={index}>
          {item.link ? (
            <Link to={item.link}>{item.label}</Link> // Para navegação via react-router
          ) : (
            item.label
          )}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
