import React, { useEffect, useState } from "react";
import { Card, Col, Row, Table, Typography, Button, Modal, Form, Input, message, Popconfirm, Badge } from "antd"; // Adicionado Input
import { UserOutlined, TeamOutlined, ApartmentOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import DashboardLayout from "../../components/LayoutPainel";
import { useBreadcrumbs } from "../../components/Breadcrumbs/context/BreadcrumbsContext";
import DynamicForm from "../../components/DynamicForm";
import { fetchData, apiPost, apiPut, apiDelete } from "../../services/api";
import { PersonIndividual } from '../../types/personIndividual'; 
import { PersonCompany } from '../../types/personCompany';
import { FormPersonIndividual } from './forms/PersonIndividual';
import { FormPersonCompany } from './forms/PersonCompany';
import { FaBuilding, FaIndustry, FaUser } from "react-icons/fa";
import { useNotification } from "../../components/Notification";

const { Title } = Typography;
const { Search } = Input; // Adicionado Search

const Clients: React.FC = () => {
  const { setBreadcrumbs } = useBreadcrumbs();
  const { openNotification } = useNotification();
  
  const [clients, setClients] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [formType, setFormType] = useState<'PF' | 'PJ' | null>(null);
  const [form] = Form.useForm();
  const [editingClient, setEditingClient] = useState<PersonIndividual | PersonCompany | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>(""); // Estado para o termo de pesquisa

  useEffect(() => {
    setBreadcrumbs([
      { label: 'Home', link: '/dashboard' },
      { label: 'Clientes', link: '/clients' },
    ]);

    loadClients(); // Carrega a lista inicial
  }, [setBreadcrumbs, searchTerm]); // Atualiza os clientes quando o termo de pesquisa muda

  // Função de carregamento dos clientes, agora com suporte à pesquisa
  const loadClients = async () => {
    setLoading(true);
    try {
      // Passar o termo de busca como parâmetro na query
      const data = await fetchData(`v1/clients?search=${searchTerm}`);
      setClients(data);
    } catch (error) {
      console.error('Erro ao carregar clientes:', error);
    }
    setLoading(false);
  };

  // Função chamada quando o usuário faz a pesquisa
  const handleSearch = (value: string) => {
    setSearchTerm(value); // Atualiza o termo de pesquisa
  };

  const showChoiceModal = () => {
    setFormType(null);
    setEditingClient(null);
    setIsModalVisible(true);
  };

  const openForm = (type: 'PF' | 'PJ') => {
    setFormType(type);
    form.resetFields();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setFormType(null);
    form.resetFields();
  };

  const handleAddOrEditClient = async (values: any) => {
    try {
      values.type = formType;

      if (formType === 'PJ') {
        values.email = values.responsible_email;
        values.name = values.company_name;
      }

      if (editingClient) {
        await apiPut(`v1/clients/${editingClient.id}`, values);
        openNotification('success', 'Sucesso', 'Cliente atualizado com sucesso!', 'topRight');
      } else {
        await apiPost('v1/clients', values);
        openNotification('success', 'Sucesso', 'Cliente adicionado com sucesso!', 'topRight');
      }

      loadClients();
      setIsModalVisible(false);
    } catch (error: any) {
      console.error('Erro ao salvar cliente:', error);
      openNotification('error', 'Erro', error.response?.data?.message || 'Erro ao salvar cliente.', 'topRight');
    }
  };

  const handleDeleteClient = async (id: number) => {
    try {
      await apiDelete(`v1/clients/${id}`);
      openNotification('success', 'Sucesso', 'Cliente removido com sucesso!', 'topRight');
      loadClients();
    } catch (error: any) {
      console.error('Erro ao remover cliente:', error);
      openNotification('error', 'Erro', error.response?.data?.message || 'Erro ao remover cliente.', 'topRight');
    }
  };

  const showModal = (client: any) => {
    setEditingClient(client);
    setFormType(client.type);
    setIsModalVisible(true);

    if (client.type === 'PJ' && client.company) {
      form.setFieldsValue({
        ...client,
        email: client.company.responsible_email,
        company_name: client.company.company_name,
        trading_name: client.company.trading_name,
        cnpj: client.company.cnpj,
        state_registration: client.company.state_registration,
        municipal_registration: client.company.municipal_registration,
        responsible_name: client.company.responsible_name,
        responsible_phone: client.company.responsible_phone,
        billing_address: client.company.billing_address,
        credit_limit: client.company.credit_limit,
        monthly_billing: client.company.monthly_billing,
        commercial_references: client.company.commercial_references,
        notes: client.company.notes,
        is_final_consumer: client.is_final_consumer 
      });
    } else if (client.type === 'PF' && client.individual) {
      form.setFieldsValue({
        ...client,
        cpf: client.individual.cpf,
        rg: client.individual.rg,
        birthdate: client.individual.birthdate,
        billing_address: client.individual.billing_address,
        monthly_income: client.individual.monthly_income,
        notes: client.individual.notes,
        is_final_consumer: client.is_final_consumer 
      });
    }
  };

  const fieldsPF = FormPersonIndividual;
  const fieldsPJ = FormPersonCompany;

  const columns = [
    {
      title: 'Nome/Razão Social',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      render: (text: string) => (text === 'PF' ? 'Pessoa Física' : 'Pessoa Jurídica'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => <Badge>{text}</Badge>,
    },
    {
      title: "Ações",
      key: "actions",
      render: (_: any, client: any) => (
        <>
          <Button icon={<EditOutlined />} onClick={() => showModal(client)} style={{ marginRight: 8 }} />
          <Popconfirm
            title="Tem certeza que deseja excluir?"
            onConfirm={() => handleDeleteClient(client.id)}
            okText="Sim"
            cancelText="Não"
          >
            <Button danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <Row justify="space-between" align="middle" style={{ marginBottom: 20 }}>
        <Col>
          <Title level={2}>Clientes</Title>
        </Col>
        <Col>
          <Button type="primary" icon={<PlusOutlined />} onClick={showChoiceModal}>
            Adicionar Cliente
          </Button>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col span={5}>
          <Card bordered={false} style={{ backgroundColor: '#e6f7ff' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <UserOutlined style={{ fontSize: '48px', color: '#1890ff', marginRight: '16px' }} />
              <div>
                <div style={{ fontSize: '16px', color: '#1890ff' }}>Total de Clientes</div>
                <div style={{ fontSize: '24px', fontWeight: 'bold' }}>{clients.length}</div>
              </div>
            </div>
          </Card>
        </Col>
        <Col span={5}>
          <Card bordered={false} style={{ backgroundColor: '#f9f0ff' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TeamOutlined style={{ fontSize: '48px', color: '#722ed1', marginRight: '16px' }} />
              <div>
                <div style={{ fontSize: '16px', color: '#722ed1' }}>Clientes PF</div>
                <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                  {clients.filter(client => client.type === 'PF').length}
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col span={5}>
          <Card bordered={false} style={{ backgroundColor: '#fff1b8' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ApartmentOutlined style={{ fontSize: '48px', color: '#fa8c16', marginRight: '16px' }} />
              <div>
                <div style={{ fontSize: '16px', color: '#fa8c16' }}>Clientes PJ</div>
                <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                  {clients.filter(client => client.type === 'PJ').length}
                </div>
              </div>
            </div>
          </Card>
        </Col>
      </Row>


      {/* Campo de pesquisa */}
      <Row justify="start" style={{ marginBottom: 20 }}>
        <Col span={8}>
          <Search placeholder="Pesquisar cliente" onSearch={handleSearch} enterButton />
        </Col>
      </Row>


      <Table columns={columns} dataSource={clients} rowKey="id" loading={loading} />

      <Modal
        title="Escolha o tipo de Cliente"
        visible={isModalVisible && !formType}
        onCancel={handleCancel}
        footer={null}
        width={400}
      >
        <Row justify="center" style={{ padding: '20px', gap: '20px' }}>
          <Col>
            <Button
              type="primary"
              onClick={() => openForm('PF')}
              style={{
                backgroundColor: '#FF5733',
                borderColor: '#FF5733',
                width: '120px',
                height: '120px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '4px',
                fontWeight: 'bold',
              }}
            >
              <FaUser size={30} style={{ marginBottom: '8px' }} />
              Pessoa Física
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={() => openForm('PJ')}
              style={{
                backgroundColor: '#33C3FF',
                borderColor: '#33C3FF',
                width: '120px',
                height: '120px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '4px',
                fontWeight: 'bold',
              }}
            >
              <FaIndustry size={30} style={{ marginBottom: '8px' }} />
              Pessoa Jurídica
            </Button>
          </Col>
        </Row>
      </Modal>

      {formType && (
        <Modal
          title={formType === 'PF' ? "Adicionar Pessoa Física" : "Adicionar Pessoa Jurídica"}
          visible={isModalVisible && !!formType}
          onCancel={handleCancel}
          footer={null}
          width={1024}
        >
          <DynamicForm
            fields={formType === 'PF' ? fieldsPF : fieldsPJ}
            form={form}
            onFinish={handleAddOrEditClient}
            handleSave={handleAddOrEditClient}
            initialValues={editingClient || {}}
          />
        </Modal>
      )}
    </DashboardLayout>
  );
};

export default Clients;
