export const FormPersonCompany = [
    {
        name: 'cnpj',
        label: 'CNPJ',
        placeholder: 'CNPJ da Empresa',
        span: 6,
        required: true,
        rules: [{ required: true, message: 'Por favor, insira o CNPJ' }],
    },
    {
    name: 'company_name',
    label: 'Razão Social',
    placeholder: 'Razão Social da Empresa',
    span: 18,
    required: true,
    rules: [{ required: true, message: 'Por favor, insira a razão social da empresa' }],
  },
  {
    name: 'trading_name',
    label: 'Nome Fantasia',
    placeholder: 'Nome Fantasia da Empresa',
    span: 18,
    required: false,
  },
  {
    name: 'credit_limit',
    label: 'Limite de Crédito',
    type: 'money_number',
    placeholder: 'Limite de crédito da empresa',
    span: 6,
    required: true,
    rules: [{ required: true, type: 'number', message: 'Por favor, insira o limite de crédito' }],
  },
  {
    name: 'state_registration',
    label: 'Inscrição Estadual',
    placeholder: 'Inscrição Estadual da Empresa',
    span: 8,
    required: true,
    rules: [{ required: true, message: 'Por favor, insira a inscrição estadual' }],
  },
  {
    name: 'municipal_registration',
    label: 'Inscrição Municipal',
    placeholder: 'Inscrição Municipal da Empresa',
    span: 9,
    required: false,
  },
  {
    name: 'monthly_billing',
    label: 'Faturamento Mensal',
    type: 'money_number',
    placeholder: 'Faturamento mensal da empresa',
    span: 7,
    required: true,
    rules: [{ required: true, type: 'number', message: 'Por favor, insira o faturamento mensal' }],
  },
  {
    name: 'responsible_name',
    label: 'Nome do Responsável',
    placeholder: 'Nome do responsável pela empresa',
    span: 8,
    required: true,
    rules: [{ required: true, message: 'Por favor, insira o nome do responsável' }],
  },
  {
    name: 'responsible_phone',
    label: 'Telefone do Responsável',
    placeholder: 'Telefone do responsável pela empresa',
    span: 8,
    required: true,
    rules: [{ required: true, message: 'Por favor, insira o telefone do responsável' }],
  },
  {
    name: 'responsible_email',
    label: 'Email do Responsável',
    placeholder: 'Email do responsável pela empresa',
    span: 8,
    required: true,
    rules: [{ required: true, type: 'email', message: 'Por favor, insira um email válido' }],
  },
 
  {
    name: 'billing_address',
    label: 'Endereço de Cobrança',
    placeholder: 'Endereço de cobrança',
    span: 24    ,
    required: true,
    rules: [{ required: true, message: 'Por favor, insira o endereço de cobrança' }],
  },
  
  
  // Campos herdados de Client
  {
    name: 'cep',
    label: 'CEP',
    placeholder: 'CEP da empresa',
    span: 5,
    required: true,
    rules: [{ required: true, message: 'Por favor, insira o CEP' }],
  },
  {
    name: 'address',
    label: 'Endereço',
    placeholder: 'Endereço da empresa',
    span: 8,
    required: true,
    rules: [{ required: true, message: 'Por favor, insira o endereço' }],
  },
  {
    name: 'text',
    label: 'Número',
    placeholder: 'Número do endereço',
    span: 4,
    required: true,
    rules: [{ required: true, message: 'Por favor, insira o número do endereço' }],
  },
  {
    name: 'complement',
    label: 'Complemento',
    placeholder: 'Complemento (opcional)',
    span: 7,
    required: false,
  },
  {
    name: 'neighborhood',
    label: 'Bairro',
    placeholder: 'Bairro da empresa',
    span: 7,
    required: true,
    rules: [{ required: true, message: 'Por favor, insira o bairro' }],
  },
  {
    name: 'city',
    label: 'Cidade',
    placeholder: 'Cidade da empresa',
    span: 6,
    required: true,
    rules: [{ required: true, message: 'Por favor, insira a cidade' }],
  },
  {
    name: 'state',
    label: 'Estado',
    placeholder: 'Estado da empresa',
    span: 6,
    required: true,
    rules: [{ required: true, message: 'Por favor, insira o estado' }],
  },
  {
    name: 'country',
    label: 'País',
    placeholder: 'País da empresa',
    span: 5,
    required: true,
    rules: [{ required: true, message: 'Por favor, insira o país' }],
  },
  {
    name: 'country_code',
    label: 'Código do País',
    placeholder: 'Código do país',
    span: 4,
    required: true,
  },
  {
    name: 'city_code',
    label: 'Código da Cidade',
    type: 'number',
    placeholder: 'Código da cidade',
    span: 4,
    required: true,
    rules: [{ required: true, type: 'number', message: 'Por favor, insira o código da cidade' }],
  },
  {
    name: 'phone',
    label: 'Telefone',
    placeholder: 'Telefone fixo da empresa',
    span: 6,
    required: false,
  },
  {
    name: 'cellphone',
    label: 'Celular',
    placeholder: 'Celular da empresa',
    span: 6,
    required: false,
  },
  {
    name: 'is_final_consumer',
    label: 'Consumidor Final',
    type: 'select',
    span: 4,
    placeholder: 'Selecione',
    options: [{
        label: 'Sim',
        value: true,
      },{
        label: 'Não',
        value: false,
      }],
    required: true,
    rules: [{ required: true, message: 'Por favor, selecione se é Consumidor Final' }],      
  },
  {
    name: 'commercial_references',
    label: 'Referências Comerciais',
    type: 'textarea',
    placeholder: 'Insira as referências comerciais',
    span: 12,
    required: false,
  },
  {
    name: 'notes',
    label: 'Observações',
    type: 'textarea',
    placeholder: 'Insira observações',
    span: 12,
    required: false,
  },
  {
    name: 'status',
    label: 'Status',
    type: 'select',
    placeholder: 'Selecione um status',
    span: 4,
    options: [{
      label: 'Ativo',
      value: 'Ativo',
    },
    {
      label: 'Inativo',
      value: 'Inativo',
    },
    {
      label: 'Bloqueado',
      value: 'Bloqueado',
    },

    ],
    required: true,
    rules: [{ required: true, message: 'Por favor, selecione um status' }],      
  }
];
