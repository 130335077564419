import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {  
    localStorage.removeItem('authToken');
    localStorage.clear();

    navigate('/login', { replace: true });
    
  }, [navigate]);

  return null; 
};

export default Logout;

    
 