export const FormPersonIndividual = [
    {
      name: 'name',
      label: 'Nome Completo',
      placeholder: 'Nome do cliente',
      span: 10,
      required: true,
      rules: [{ required: true, message: 'Por favor, insira o nome completo' }],
    },
    {
      name: 'email',
      label: 'Email',
      placeholder: 'Email do cliente',
      span: 8,
      required: true,
      rules: [{ required: true, type: 'email', message: 'Por favor, insira um email válido' }],
    },
    {
        name: 'birthdate',
        label: 'Data de Nascimento',
        type: 'date',
        placeholder: 'Data de Nascimento',
        span: 6,
        required: false,
      },
    {
      name: 'cpf',
      label: 'CPF',
      placeholder: 'CPF do cliente',
      span: 6,
      required: true,
      rules: [{ required: true, message: 'Por favor, insira o CPF' }],
    },
    {
      name: 'rg',
      label: 'RG',
      placeholder: 'RG do cliente',
      span: 6,
      required: false,
    },
    
    // {
    //   name: 'contact_phone',
    //   label: 'Telefone de Contato',
    //   placeholder: 'Telefone de contato',
    //   span: 6,
    //   required: false,
    // },
    {
        name: 'monthly_income',
        label: 'Renda Mensal',
        type: 'number',
        placeholder: 'Renda mensal',
        span: 6,
        required: false,
        rules: [{ type: 'number', message: 'Por favor, insira um valor válido' }],
      },
    {
      name: 'credit_limit',
      label: 'Limite de Crédito',
      type: 'number',
      placeholder: 'Limite de crédito',
      span: 6,
      required: false,
      rules: [{ type: 'number', message: 'Por favor, insira um número válido' }],
    },
    {
        name: 'cellphone',
        label: 'Celular',
        placeholder: 'Celular do cliente',
        span: 6,
        required: false,
      },
    {
      name: 'billing_address',
      label: 'Endereço de Cobrança',
      placeholder: 'Endereço de cobrança',
      span: 18,
      required: false,
    },
    
    {
      name: 'cep',
      label: 'CEP',
      placeholder: 'CEP do cliente',
      span: 6,
      required: true,
      rules: [{ required: true, message: 'Por favor, insira o CEP' }],
    },
    {
      name: 'address',
      label: 'Endereço',
      placeholder: 'Endereço do cliente',
      span: 12,
      required: true,
      rules: [{ required: true, message: 'Por favor, insira o endereço' }],
    },
    {
      name: 'text',
      label: 'Número',
      placeholder: 'Número do endereço',
      span: 6,
      required: true,
      rules: [{ required: true, message: 'Por favor, insira o número do endereço' }],
    },
    {
      name: 'complement',
      label: 'Complemento',
      placeholder: 'Complemento (opcional)',
      span: 7,
      required: false,
    },
    {
      name: 'neighborhood',
      label: 'Bairro',
      placeholder: 'Bairro do cliente',
      span: 6,
      required: true,
      rules: [{ required: true, message: 'Por favor, insira o bairro' }],
    },
    {
      name: 'city',
      label: 'Cidade',
      placeholder: 'Cidade do cliente',
      span: 6,
      required: true,
      rules: [{ required: true, message: 'Por favor, insira a cidade' }],
    },
    {
      name: 'state',
      label: 'Estado',
      placeholder: 'Estado do cliente',
      span: 5,
      required: true,
      rules: [{ required: true, message: 'Por favor, insira o estado' }],
    },
    {
      name: 'country',
      label: 'País',
      placeholder: 'País do cliente',
      span: 6,
      required: true,
      rules: [{ required: true, message: 'Por favor, insira o país' }],
    },
    {
      name: 'country_code',
      label: 'Código do País',
      placeholder: 'Código do país',
      span: 6,
      required: true,
    },
    {
      name: 'city_code',
      label: 'Código da Cidade',
      type: 'number',
      placeholder: 'Código da cidade',
      span: 4,
      required: false,
      // rules: [{ required: true, type: 'number', message: 'Por favor, insira o código da cidade' }],
    },
    {
      name: 'phone',
      label: 'Telefone',
      placeholder: 'Telefone fixo do cliente',
      span: 8,
      required: false,
    },
   
    {
        name: 'is_final_consumer',
        label: 'Consumidor Final',
        type: 'select',
        span: 5,
        placeholder: 'Selecione',
        options: [{
            label: 'Sim',
            value: true,
          },{
            label: 'Não',
            value: false,
          }],
        required: true,
        rules: [{ required: true, message: 'Por favor, selecione se é Consumidor Final' }],      
      },
      {
        name: 'notes',
        label: 'Observações',
        type: 'textarea',
        placeholder: 'Insira observações',
        span: 18,
        required: false,
      },
      {
        name: 'status',
        label: 'Status',
        type: 'select',
        placeholder: 'Selecione um status',
        span: 4, 
        options: [{
          label: 'Ativo',
          value: 'Ativo',
        },
        {
          label: 'Inativo',
          value: 'Inativo',
        },
        {
          label: 'Bloqueado',
          value: 'Bloqueado',
        },

        ],
        required: true,
        rules: [{ required: true, message: 'Por favor, selecione um status' }],      
      }
  ];
  