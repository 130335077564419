export const validateCEP = (_: any, value: string) => {
  const cep = value.replace(/[^\d]+/g, ''); // Remove qualquer caractere não numérico
  
  if (cep.length !== 8) {
    return Promise.reject('CEP inválido');
  }
  return Promise.resolve();
};

export const maskCEP = (value: string) => {
  return value.replace(/\D/g, '').replace(/^(\d{5})(\d{1,3})/, '$1-$2').replace(/(-\d{3})\d+?$/, '$1');
};